import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";

import { useRef } from "react";
import IMAGES from "../../images/index.js";

const Navbar = () => {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive-nav");
  };

  return ( 
    <div className="navbarMain">
      <nav className="navMain ">
        <div className="navLogo">
          <div>
            <span className="naveName">Drafting Dots</span>
          </div>
          <div className="underText">
            An interior and exterior design studio
          </div>
        </div>
        <div className="navComp" ref={navRef}>
          <ul className="navUl">
            <li className="liNav">
              <Link id="Navigate" to="/">
                {" "}
                Home
              </Link>{" "}
            </li>
            <li className="liNav">
              <Link id="Navigate" to="/services">
                Services
              </Link>{" "}
            </li>
            <li className="liNav">
              <Link id="Navigate" to="/contact">
                Contact Us
              </Link>{" "}
            </li>
            <div className="nav-btn nab-close-btn" onClick={showNavbar}>
              <img src={IMAGES.Img20} alt="" />
            </div>
          </ul>
        </div>

        <div className="nav-btn " onClick={showNavbar}>
          <img src={IMAGES.Img30} alt="" />
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
