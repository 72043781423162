import React from "react";
import "./services.css";
import IMAGES from "../../images/index.js"

const Services = () => {
  return (
    
    
       <div className="box">
        <div className="row row-cols-1 row-cols-md-3 g-4"> 
           <div className="col " >
            <div className="card h-100">
              <img src={IMAGES.Img14} className="card-img-top  h-50" alt="..." />
              <div className="card-body " >
                <h5 className="card-title text-warning">Exterior Design</h5>
                <p className="card-text" >
                We Design Exterior Of A House, Involving Creating A Visually Appealing And Functional Space That Reflects The Homeowner's Preferences, Lifestyle, And The Architectural Style Of The Residence.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={IMAGES.Img15} className="card-img-top h-50" alt="..." />
              <div className="card-body " >
                <h5 className="card-title text-warning"> Residential Interior Design</h5>
                <p className="card-text ">We Do All Types Of Interior Designing, Decoration And Furnishing Of Bed Room, Living Room, Dinning Room, Hall Room, Home Entertainment Room, Kids Room, Study Room, Guest Room So That Each Room Can Perfectly Brighten Up Your Environment And Enhance Your Mood.</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={IMAGES.Img16} className="card-img-top h-50" alt="..." />
              <div className="card-body ">
                <h5 className="card-title text-warning">2D/3D Design Layout</h5>
                <p className="card-text ">
                We Do All Types Of 2D And 3D Elegant ,Innovative, Luxurious Computerised Interior And Exterior Design.
                </p>
              </div>
            </div>
          </div> 
          
          <div className="col">
            <div className="card h-100">
              <img src={IMAGES.Img22} className="card-img-top h-50" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-warning">Modular Kitchen</h5>
                <p className="card-text">
                We Do All Types Of Modular Kitchen Designing And Decoration As Well As Luxury Kitchen Designing, To Make Your Kitchen Your Favourite Place.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={IMAGES.Img23} className="card-img-top h-50" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-warning">Office Interior</h5>
                <p className="card-text">
                We Offer All Types Of Interior Designing Solutions And Decoration For Offices And Other Commercial Spaces That Create An Environment Perfectly Blending With Your Mindset To Grow Your Business.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={IMAGES.Img24} className="card-img-top h-50" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-warning">Retail Shop & Showroom</h5>
                <p className="card-text">
                We Offer All Types Of Interior Designing Solutions For Retail Store, Showrooms Or Exhibition Hall So That Your Customers Also Gets A Reason To Stay Loyal With You.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={IMAGES.Img25} className="card-img-top h-50" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-warning">Restuarants & Cafeteria</h5>
                <p className="card-text">
                We Offer All Types Of Interior Designing Solutions For Restaurant, Coffee Shops And Cafeteria, So That People Get A Reason To Spend More Time In Your Place.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={IMAGES.Img26} className="card-img-top h-50" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-warning">Furniture & Fixture</h5>
                <p className="card-text">
                We Make Custom-Designed Furniture And Deal With All Types Of Other Modular Furniture And Fixture, So That You Can Get Exactly What You Want.
                </p>
              </div>
            </div>
          </div>
          
          
        </div>
      </div> 
      
  );
};

export default Services;
