import React from "react";
import IMAGES from "../../images/index.js";
import "./home.css";

const Home = () => {
  return (
    <div>
      <div className="homeMain"> 
        <div className="rightSection">
          <div className="sect1">Designs we live in</div>
          <div className="sect2">shape our lives!</div>
        </div>
      </div>
      <div className="aboutUs">
        <div className="aboutUsText">
          <div className="title">About Us</div>
          <div className="Description">
            At Drafting Dots, we believe that a well-designed space has the
            power to transform lives. Our passion for creating stunning and
            functional designs drives everything we do, from the initial concept
            to the final execution. With a vision to provide quality designs,
            Drafting Dots was founded in 2023. Our team of talented and
            experienced designers is dedicated to bringing your vision to life,
            creating spaces that reflect your unique style, preferences, and
            functionality needs.
          </div>
        </div>
      </div>

      <div className="demoPhotos">
        <div className="photoTitle">Our designs...</div>
        <div className="Photos">
          <img className="galleryImg" src={IMAGES.Img31} alt="" />
          <img className="galleryImg" src={IMAGES.Img32} alt="" />
          <img className="galleryImg" src={IMAGES.Img33} alt="" />
          <img className="galleryImg" src={IMAGES.Img34} alt="" />
          <img className="galleryImg" src={IMAGES.Img35} alt="" />
          <img className="galleryImg" src={IMAGES.Img36} alt="" />
          <img className="galleryImg" src={IMAGES.Img37} alt="" />
          <img className="galleryImg" src={IMAGES.Img38} alt="" />
          <img className="galleryImg" src={IMAGES.Img39} alt="" />
          <img className="galleryImg" src={IMAGES.Img40} alt="" />
          <img className="galleryImg" src={IMAGES.Img41} alt="" />
        </div>
      </div>
    
     
      <div className='contactHome'>
        <div className="contactImg"><img src={IMAGES.Img17} alt="" /></div>
        <div className="contactDetails">
            <div className="address"><b>Address [A]</b> : Drafting Dots, Connaught Cir, Block-M, Connaught Place, New Delhi, Delhi-110001</div>
            <div className="address"><b>Address [B]</b> : Drafting Dots, Ganeshguri, Guwahati, Assam-781005 </div>
            <div className="contactNo"> <b>Contact No</b>: 6000017804(for N.E. region),9654876205(for Delhi)</div>
            <div className="email"><b>E-mail</b>: business@draftingdots.in</div>
        </div> 
    </div>
    </div>
    
  );
};

export default Home;
