const IMAGES = {
  Img1: require("./2.jpg"),
  Img3: require("./4.jpg"),
  Img8: require("./9.jpg"),
  Img9: require("./10.jpg"),
  Img11: require("./12.jpg"),
  Img12: require("./13.jpg"),
  Img13: require("./int1.jpg"),
  Img14: require("./Elevation design.jpg"),
  Img15: require("./interior res.jpg"),
  Img16: require("./3d floor.jpg"),
  Img17: require("./Contact-removebg-preview.png"),
  Img20: require("./cross-N.png"),
  Img22: require("./modular kitchen.jpg"),
  Img23: require("./ofiice int.jpg"),
  Img24: require("./shop int.jpg"),
  Img25: require("./restaurant.jpg"),
  Img26: require("./furniture des.jpg"),
  Img27: require("./falseC.jpg"),
  Img28: require("./background.jpg"),
  Img29: require("./background-modified.jpg"),
  Img30: require("./hamburger-N.png"),
  Img31: require("./bath1.jpg"),
  Img32: require("./bed2.jpg"),
  Img33: require("./bed3.jpg"),
  Img34: require("./entry.jpg"),
  Img35: require("./kitchen1.jpg"),
  Img36: require("./kitchen3.jpg"),
  Img36: require("./kitchen5.jpg"),
  Img37: require("./exterior1.jpg"),
  Img38: require("./office w2.jpg"),
  Img39: require("./falseC1.jpg"),
  Img40: require("./w8.jpg"),
  Img41: require("./w6.jpg"),
};

export default IMAGES;
