import React from 'react';
import IMAGES from "../../images/index.js";
import "./contactUs.css"

const ContactUs = () => {
  return (
    <div className='contactMain'>
        <div className="contactImg"><img src={IMAGES.Img17} alt="" /></div>
        <div className="contactDetails">
            <div className="address"><b>Address [A]</b> : Drafting Dots, Connaught Cir, Block-M, Connaught Place, New Delhi, Delhi-110001</div>
            <div className="address"><b>Address [B]</b> : Drafting Dots, Ganeshguri, Guwahati, Assam-781005 </div>
            <div className="contactNo"> <b>Contact No</b>: 6000017804(for N.E. region),9654876205(for Delhi)</div>
            <div className="email"><b>E-mail</b>: business@draftingdots.in</div>
        </div> 
    </div>
  )
}

export default ContactUs
